// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PC6CitTbd", "Vu6YcCPUm", "DsCNRk1ej"];

const variantClassNames = {DsCNRk1ej: "framer-v-1mso3u2", PC6CitTbd: "framer-v-114u1mt", Vu6YcCPUm: "framer-v-9tfyk1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "PC6CitTbd", "Variant 2": "Vu6YcCPUm", "Variant 3": "DsCNRk1ej"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, jiGDbzs8w: title ?? props.jiGDbzs8w ?? "Domov", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PC6CitTbd"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jiGDbzs8w, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PC6CitTbd", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jr9tK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-114u1mt", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PC6CitTbd"} ref={ref} style={{...style}} {...addPropertyOverrides({DsCNRk1ej: {"data-framer-name": "Variant 3"}, Vu6YcCPUm: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Domov</motion.p></React.Fragment>} className={"framer-1k0w18m"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"dpx2gLIj3"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={jiGDbzs8w} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jr9tK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jr9tK .framer-1efvneq { display: block; }", ".framer-jr9tK .framer-114u1mt { height: 24px; overflow: visible; position: relative; width: 54px; }", ".framer-jr9tK .framer-1k0w18m { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 54
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Vu6YcCPUm":{"layout":["fixed","fixed"]},"DsCNRk1ej":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"jiGDbzs8w":"title"}
 * @framerImmutableVariables false
 */
const FramerGC04tc3kW: React.ComponentType<Props> = withCSS(Component, css, "framer-jr9tK") as typeof Component;
export default FramerGC04tc3kW;

FramerGC04tc3kW.displayName = "Domov";

FramerGC04tc3kW.defaultProps = {height: 24, width: 54};

addPropertyControls(FramerGC04tc3kW, {variant: {options: ["PC6CitTbd", "Vu6YcCPUm", "DsCNRk1ej"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, jiGDbzs8w: {defaultValue: "Domov", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerGC04tc3kW, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/GC04tc3kW:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf", weight: "500"}])